


















































































































































































































































































































































































































































import MeterSubmit from '@/components/MeterSubmit.vue'
import Tip from '@/components/Tip.vue'
import VMeter from '@/components/Meter.vue'
import CustomLink from '@/components/g/CustomLink.vue'

import { useGetters, useMutations, useState } from '@u3u/vue-hooks'
import {
  defineComponent,
  onMounted,
  Ref,
  ref,
  watch,
} from '@vue/composition-api'
import { GenericObject } from '@/inc/types/global'
import { fetchMeters, fetchMetersByToken } from '@/composables/meterReading'

export default defineComponent({
  name: 'meter-wizard',
  components: {
    MeterSubmit,
    Tip,
    VMeter,
    CustomLink,
  },

  setup(props, ctx) {
    const currentMeter = ref<GenericObject>({})
    const { VUE_APP_MYRESA_DOMAIN } = process.env
    const currentView = ref<string>('index')
    const isLoading = ref(false)
    const submittedData = ref<GenericObject>({})
    const { currentLang } = useGetters(['currentLang'])
    const redirectMyresa = `${VUE_APP_MYRESA_DOMAIN}/${currentLang.value}/signup/`
    const state = {
      ...useState('meters', [
        'ean',
        'eans',
        'token',
        'meters',
        'type',
        'backUrl',
      ]),
    }
    const getters = {
      ...useGetters('meters', ['type', 'getEan']),
    }
    const mutations = {
      ...useMutations('meters', ['SET_EAN', 'SET_BACKURL', 'SET_TOKEN']),
    }
    const { resource } = useState(['resource'])
    const { chrome } = useState(['chrome'])

    const { $route, $i18n } = ctx.root
    const fromCommune: Ref<boolean> = ref(false)
    const setSubmitted = data => {
      submittedData.value = data
    }

    const setDefaultView = () => {
      if (state.eans.value.length === 1) {
        currentView.value = 'submit-index'
      } else if (state.eans.value.length > 1) {
        currentView.value = 'index'
      }
    }

    const checkUrlParams = () => {
      const { e: ean, c: meter, tr, commune, t: token } = $route.query as {
        e: string
        c: string
        tr: string
        commune: string
        t: string
      }

      if (commune) {
        fromCommune.value = true
      }

      if (token) {
        isLoading.value = true

        let backUrl = `${chrome.value?.data?.globalPages?.index?.path}`
        if (backUrl === 'undefined') {
          backUrl =
            $i18n.locale === 'de'
              ? '/de/mein-situation/mein-zahlerstand/meine-zahlerablesung/'
              : '/fr/ma-situation/mon-index/releve-index/'
        }
        mutations.SET_BACKURL(backUrl)
        fetchMetersByToken(token, tr, $i18n.locale)
          .then(() => {
            setDefaultView()
            isLoading.value = false
          })
          .catch(() => {
            isLoading.value = false
            ctx.root.$router
              .push({ path: state.backUrl.value })
              .catch(ctx.root.$logger.trace)
          })
      } else if (ean && meter) {
        isLoading.value = true

        let backUrl = `${chrome.value?.data?.globalPages?.index?.path}`
        if (backUrl === 'undefined') {
          backUrl =
            $i18n.locale === 'de'
              ? '/de/mein-situation/mein-zahlerstand/meine-zahlerablesung/'
              : '/fr/ma-situation/mon-index/releve-index/'
        }
        mutations.SET_BACKURL(backUrl)

        fetchMeters(ean, meter, tr, $i18n.locale)
          .then(() => {
            setDefaultView()
            isLoading.value = false
          })
          .catch(() => {
            isLoading.value = false
            ctx.root.$router.push(backUrl).catch(ctx.root.$logger.trace)
          })
      } else {
        setDefaultView()
      }
    }

    onMounted(() => {
      checkUrlParams()
      // ;[currentMeter.value] = state.meters.value
    })

    watch(currentView, () => {
      if (!ctx.root.$isServer) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }
    })

    const showMeter = meter => {
      mutations.SET_EAN(meter.ean)
      mutations.SET_EAN(meter.token)
      currentView.value = 'submit-index'
    }

    const setView = view => (currentView.value = view)

    return {
      ...state,
      ...getters,
      currentMeter,
      currentView,
      isLoading,
      setSubmitted,
      setView,
      showMeter,
      resource,
      submittedData,
      fromCommune,
      // strings: resource.value.i18n,
      redirectMyresa,
    }
  },
})
