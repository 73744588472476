import axios from 'axios'
import { useGetters, useMutations } from '@u3u/vue-hooks'
import { getApiUrl } from '@/inc/app.config'
const { currentLang } = useGetters(['currentLang'])

export function fetchMeters(
  ean: string,
  meter: string,
  type: string | boolean,
  lang = currentLang.value === 'de' ? 'DE' : 'FR'
) {
  const mutations = {
    ...useMutations('meters', [
      'SET_EAN',
      'SET_TYPE',
      'SET_ERROR',
      'RESET_EANS',
      'RESET_TOKEN',
      'ADD_EAN',
    ]),
  }

  return axios
    .get(
      `${getApiUrl()}/index?Ean=${ean}&NumCompteur=${meter}&Langue=${lang}&tr=${type}`
    )
    .then(res => {
      try {
        if (type) {
          mutations.SET_TYPE(type)
        }

        mutations.RESET_TOKEN()
        mutations.RESET_EANS()
        res.data.eans?.forEach(item => {
          mutations.ADD_EAN(item)
        })

        mutations.SET_EAN(ean)
      } catch {
        // eslint-disable-next-line no-throw-literal
        throw { response: res }
      }
    })
    .catch(error => {
      const errorMessage =
        error.response?.data?.message ||
        error.response.data?.Eans[0]?.Messages[0]?.message

      mutations.RESET_TOKEN()
      mutations.RESET_EANS()
      mutations.SET_ERROR(errorMessage)

      return Promise.reject(error)
    })
}

export function fetchMetersByToken(
  token: string,
  type: string | boolean,
  lang = currentLang.value === 'de' ? 'DE' : 'FR'
) {
  const mutations = {
    ...useMutations('meters', [
      'SET_TOKEN',
      'SET_TYPE',
      'SET_ERROR',
      'RESET_EANS',
      'ADD_EAN',
    ]),
  }

  return axios
    .get(`${getApiUrl()}/index?Token=${token}&Langue=${lang}&tr=${type}`)
    .then(res => {
      try {
        if (type) {
          mutations.SET_TYPE(type)
        }

        mutations.RESET_EANS()
        res.data.eans?.forEach(item => {
          mutations.ADD_EAN(item)
        })

        mutations.SET_TOKEN(token)
      } catch {
        // eslint-disable-next-line no-throw-literal
        throw { response: res }
      }
    })
    .catch(error => {
      const errorMessage =
        error.response?.data?.message ||
        error.response.data?.Eans[0]?.Messages[0]?.message

      mutations.RESET_EANS()
      mutations.SET_ERROR(errorMessage)

      return Promise.reject(error)
    })
}
