


































































































































































































































































































































import VMeter from '@/components/VMeter.vue'
import VInput from '@/components/form/VInput.vue'

import axios from 'axios'
import { computed, defineComponent, onMounted, ref } from '@vue/composition-api'
import { useActions, useGetters, useMutations, useState } from '@u3u/vue-hooks'
import { getApiUrl } from '@/inc/app.config'
import { GenericObject } from '@/inc/types'
import Tip from '@/components/Tip.vue'
import TipPanel from '@/components/TipPanel.vue'
import VCheckbox from '@/components/form/VCheckbox.vue'
import auth from '@/composables/auth'
import userAccountRequest from '@/composables/userAccountRequest'
import VueRecaptcha from 'vue-recaptcha'
import { regex } from '../composables/regex'

export default defineComponent({
  name: 'submit-index',
  components: {
    VMeter,
    VInput,
    Tip,
    TipPanel,
    VCheckbox,
    VueRecaptcha,
  },
  props: {
    meter: {
      type: Object,
      required: true,
    },
  },

  setup(props, ctx) {
    const isLoading = ref(false)
    const isConfirmationNeeded = ref(false)
    const emailInput = ref('')
    const { showPanel } = useActions('sidePanel', ['showPanel'])

    const userPreference = ref('com_encod_index_mail')
    const state = {
      ...useState('meters', [
        'type',
        'ean',
        'eans',
        'token',
        'email',
        'backUrl',
        'isPreferencesSet',
      ]),
    }
    const mutations = {
      ...useMutations('meters', [
        'SET_AS_SUBMITTED',
        'SET_EMAIL',
        'SET_PREFERENCE',
      ]),
    }
    const getters = {
      ...useGetters('meters', ['inputs', 'type', 'meters', 'getEan']),
    }
    const { resource } = useState(['resource'])
    const { chrome } = useState(['chrome'])
    const recaptchaKey = ref(chrome.value.data.recaptcha)
    const recaptcha = ref('')
    const { currentLang } = useGetters(['currentLang'])
    const defaultEmail = ref(
      new URLSearchParams(window.location.search).get('email')
    )
    const data = ref<GenericObject>({
      Ean: getters.getEan || '',
      // Token: state.token.value || '',
      Email: state.email.value,
      CodeConfirm: '',
      ChangeFour: state.type.value === 'cf' ? 'X' : '',
      Informatif: state.type.value === 'ah' ? 'X' : '',
      CatReleve: 'WEB',
      Compteurs: [],
    })
    const fromCommune: boolean | undefined = Boolean(
      ctx.root.$route.query.commune && ctx.root.$route.query.commune === 'true'
    )
    const findRegister = (id): GenericObject | null => {
      let register = null

      data.value.Compteurs.some(
        meter =>
          (register = meter.IndexReleve.find(item => item.NumCadran === id))
      )

      return register
    }

    const setRegistersOptions = (error, isSubmitted) => {
      data.value.Compteurs.forEach(meter => {
        meter.IndexReleve.forEach(register => {
          register.error = error
          register.isSubmitted = isSubmitted
        })
      })
    }

    const setRegisterErrorMessage = messages => {
      messages.forEach(error => {
        const register = findRegister(error.numCadran)

        if (register) {
          register.error = error.message || chrome.value.data.i18n.error
          register.isSubmitted = false
        }
      })
    }

    const sendData = values => {
      isLoading.value = true
      values.Email = state.email.value
      values.Token = state.token.value
      axios
        .post(`${getApiUrl()}/index?Token=${values.Token}`, values)
        .then(res => {
          setRegistersOptions(false, true)
          isConfirmationNeeded.value = false

          // Handle warning if values data is wired
          if (
            res.data.messages.some(
              message =>
                /^4\d{2}/.test(message.number) && message.number !== '435'
            )
          ) {
            setRegisterErrorMessage(res.data.messages)
            isConfirmationNeeded.value = true
            values.CodeConfirm = 'X'
            isLoading.value = false

            return
          }

          if (
            data.value.Compteurs.every(meter =>
              meter.IndexReleve.every(register => register.isSubmitted)
            )
          ) {
            if (!state.email.value) {
              mutations.SET_EMAIL(defaultEmail.value)
            }

            mutations.SET_PREFERENCE(true)
            mutations.SET_AS_SUBMITTED(values)
            mutations.SET_EMAIL(state.email.value)
            ctx.emit('submitted', data.value)
            ctx.emit('show-view', 'result')
          }

          isLoading.value = false
        })
        .catch(error => {
          setRegistersOptions(
            error.response?.data.message || chrome.value.data.i18n.error,
            false
          )
          // mutations.SET_PREFERENCE(true)
          // mutations.SET_EMAIL(email.value)
          // mutations.SET_AS_SUBMITTED(values)
          // ctx.emit('submitted', data.value)
          // ctx.emit('show-view', 'result')
          isLoading.value = false
        })
    }

    const setUserPreference = async () => {
      if (state.isPreferencesSet.value) {
        return
      }

      const data = {
        // email: defaultEmail.value,
      }

      Object.defineProperty(data, userPreference.value, {
        writable: false,
        enumerable: true,
        value: true,
      })

      const headers: Record<string, string> = {}

      if (auth.currentUser.value === null) {
        const ghost = await auth.currentGhostAsync

        if (ghost?.sessionId) {
          headers.SessionId = ghost.sessionId
        }
      }
      /* eslint-disable */
      const { error } = await userAccountRequest('users', 'patch', {
        Email: defaultEmail.value,
        Preferences: { com_encod_index_mail: true },
      })
      /*axios
        .post(`${getApiUrl()}/me/preferences`, data, {
          headers,
        })
        .then(response => {
          if (response.status === 200) {
            axios.patch(`${getApiUrl()}/users`)
          }
        })
        .catch(error => {
          ctx.root.$logger.error(error)
        }) */
    }

    const onSubmit = () => {
      setUserPreference()
      sendData(data.value)
    }

    const onBack = () => {
      if (state.eans.value.length === 1) {
        ctx.root.$router
          .push({ path: state.backUrl.value })
          .catch(ctx.root.$logger.trace)
      } else if (state.eans.value.length > 1) {
        ctx.emit('show-view', 'index')
      }
    }

    const onInput = () => {
      data.value.CodeConfirm = ''
    }

    const meterIcon = computed(() =>
      // eslint-disable-next-line
      require(`@/assets/svg/icons/${getters.type.value}New.svg`)
    )

    const setPostData = () => {
      getters.meters.value.forEach((meter, meterIndex) => {
        data.value.Compteurs.push({
          NumCompteur: meter.numCompteur || '2341990',
          NumEquip: meter.numEquip,
          IndexReleve: [],
        })

        meter.registres.forEach(register => {
          data.value.Compteurs[meterIndex].IndexReleve.push({
            NbChiffre: register.nbChiffre,
            NbDecimales: register.nbDecimales,
            NumCadran: register.numCadran,
            PosCadran: register.posCadran,
            TypeCadran: register.typeCadran,
            IndexReleve: '',
            isSubmitted: false,
            error: false,
          })
        })
      })
    }

    const getRegister = (meterIndex, registerIndex) =>
      data.value.Compteurs[meterIndex]?.IndexReleve[registerIndex]

    onMounted(() => {
      setPostData()
      if (!state.email.value) {
        mutations.SET_EMAIL(defaultEmail)
      }
    })

    const showGDPRPanel = () => {
      axios
        .get(`${getApiUrl()}/faq/dpo/`)
        .then(({ data }) => {
          showPanel({
            component: {
              template: TipPanel,
            },
            content: data.content,
          })
        })
        .catch(error => {
          console.log(error)
          console.log('Fetch TIP DPO fail')
        })
    }

    const setRecaptcha = (res: string) => {
      recaptcha.value = res
    }

    const resetRecaptcha = () => {
      recaptcha.value = ''
    }

    return {
      ...mutations,
      ...state,
      ean: getters.getEan,
      data,
      defaultEmail,
      getRegister,
      inputs: getters.inputs,
      isConfirmationNeeded,
      isLoading,
      meterIcon,
      meters: getters.meters,
      onBack,
      onInput,
      onSubmit,
      strings: resource.value.i18n,
      stringsShared: chrome.value.data.i18n,
      type: getters.type,
      userPreference,
      fromCommune,
      emailInput,
      showGDPRPanel,
      recaptchaKey,
      recaptcha,
      setRecaptcha,
      resetRecaptcha,
      currentLang,
      emailRegex: regex.email,
    }
  },
})
