var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.strings && _vm.stringsShared)?_c('div',{staticClass:"submit-index"},[_c('h1',[_vm._v(_vm._s(_vm.strings.reading))]),_c('div',{staticClass:"info"},[(_vm.type)?_c('span',{staticClass:"info__type"},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"header__icon",class:[_vm.type],attrs:{"size":"0 0 32 32","symbol":("icons-" + _vm.type + "New")}}),_vm._v(" "+_vm._s(_vm.strings[_vm.type])+" ")]):_vm._e(),_c('span',{staticClass:"info__ids"},[_vm._v(" EAN: "+_vm._s(_vm.ean)+" "),_c('br')])]),_c('div',{staticClass:"h4 m-xs"},[_vm._v(_vm._s(_vm.strings.encodingIndex))]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('div',{staticClass:"values"},[_c('div',{staticClass:"values__items"},_vm._l((_vm.meters),function(meter,meterIndex){return _c('div',{key:("meter-" + meterIndex),staticClass:"value"},[_c('strong',{staticClass:"mb-xxs h4"},[_vm._v(" "+_vm._s(_vm.strings.meter)+": "+_vm._s(_vm._f("trimzeros")(meter.numCompteur))+" ")]),_vm._l((meter.registres),function(register,registerIndex){return _c('div',{key:("regitster-" + registerIndex),staticClass:"values__items__item"},[_c('div',{staticClass:"value__headline mb-xxs"},[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(register.posCadran)+" ")])]),_c('div',{staticClass:"value__meter"},[(_vm.getRegister(meterIndex, registerIndex))?_c('v-meter',{staticClass:"meter--big",class:{
                  'is-submitted': _vm.getRegister(meterIndex, registerIndex)
                    .isSubmitted,
                },attrs:{"decimals":parseInt(register.nbDecimales),"disabled":_vm.getRegister(meterIndex, registerIndex).isSubmitted,"integers":parseInt(register.nbChiffre),"maxlength":parseInt(register.nbChiffre) +
                  parseInt(register.nbDecimales),"minlength":parseInt(register.nbChiffre) +
                  parseInt(register.nbDecimales),"required":true},on:{"input":_vm.onInput},model:{value:(_vm.getRegister(meterIndex, registerIndex).IndexReleve),callback:function ($$v) {_vm.$set(_vm.getRegister(meterIndex, registerIndex), "IndexReleve", $$v)},expression:"getRegister(meterIndex, registerIndex).IndexReleve"}}):_vm._e(),(_vm.type === 'elec')?_c('span',[_vm._v("kWh")]):_vm._e(),(_vm.type === 'gaz')?_c('span',[_vm._v("m"),_c('sup',[_vm._v("3")])]):_vm._e()],1),(
                _vm.getRegister(meterIndex, registerIndex) &&
                _vm.getRegister(meterIndex, registerIndex).error
              )?_c('p',{staticClass:"g-error"},[_vm._v(" "+_vm._s(_vm.getRegister(meterIndex, registerIndex).error)+" ")]):_vm._e()])})],2)}),0)]),_c('div',{staticClass:"mt-xl mb-m"},[_c('h3',{staticClass:"mb-xxs",domProps:{"innerHTML":_vm._s(_vm.strings.emailAdresse)}}),_c('p',{staticClass:"mb-xs",domProps:{"innerHTML":_vm._s(_vm.strings.emailText)}}),_c('v-input',{staticClass:"mb-xxs",attrs:{"required":true,"type":"email","id":"email","pattern":_vm.emailRegex,"label":"Email"},model:{value:(_vm.email.value),callback:function ($$v) {_vm.$set(_vm.email, "value", $$v)},expression:"email.value"}})],1),_c('div',{staticClass:"col-xs-20 col-l-20"},[_c('v-checkbox',{staticClass:"mt-xs request__info",attrs:{"id":"gdpr","label":((_vm.stringsShared.rgpdMention) + " "),"required":true,"labelStyle":{ color: '#575756' }}},[_c('g-action',{staticClass:"form__gdpr",attrs:{"content":{
            label: _vm.stringsShared.rgpdLink,
            url: '#',
          }},nativeOn:{"click":function($event){$event.preventDefault();return _vm.showGDPRPanel($event)}}})],1),_c('p',{staticClass:"text-small mt-l",domProps:{"innerHTML":_vm._s(_vm.strings.captcha)}}),(_vm.recaptchaKey)?_c('vue-recaptcha',{attrs:{"id":"captcha","required":true,"language":_vm.currentLang === 'de' ? _vm.currentLang : 'fr',"loadRecaptchaScript":true,"sitekey":_vm.recaptchaKey},on:{"expired":_vm.resetRecaptcha,"verify":_vm.setRecaptcha}}):_vm._e()],1),_c('div',[_c('div',{staticClass:"nav mt-m"},[_c('a',{staticClass:"nav__back",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.onBack($event)}}},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],attrs:{"size":"0 0 22 14","symbol":"ui-arrow"}}),_c('span',[_vm._v(_vm._s(_vm.stringsShared.back))])]),_c('g-btn',{staticClass:"nav__submit",class:{ 'is-loading': _vm.isLoading },attrs:{"btn":{
            label: _vm.isConfirmationNeeded
              ? _vm.strings.confirm
              : _vm.stringsShared.submit,
          },"disabled":!_vm.recaptcha,"tag":"button","type":"submit"}})],1)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }